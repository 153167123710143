import { useReducer, useCallback } from 'react';
import { RequestStatus } from "@/Services/Axios";
import { TODO_ANY } from "@/Types/CommonTypes";
import { logger } from '@/Services/Logger';


enum FETCH_ACTIONS {
  START = 'START',
  FINISH_SUCCESS = 'FINISH_SUCCESS',
  FINISH_ERROR = 'FINISH_ERROR',
  RESET = 'RESET',
  UPDATE = 'UPDATE',
}

export interface IFetchState<T> {
  fail: boolean;
  data: T;
  headers: TODO_ANY;
  error: TODO_ANY | string;
  status: RequestStatus;
};

const initialState: IFetchState<null> = {
  fail: false,
  data: null,
  headers: null,
  error: null,
  status: RequestStatus.IDLE,
};

export type typeQueryFunction = <ARGUMENTS>(queryFn: (...args: ARGUMENTS[]) => Promise<TODO_ANY>, ...arg: ARGUMENTS[]) => Promise<boolean>;
type typeUpdateFunction<T> = (payload: Partial<IFetchState<T>>) => void


const reducerFn = (state: IFetchState<null>, action: {type: FETCH_ACTIONS, payload?: TODO_ANY}) => {
  switch (action.type) {
    case FETCH_ACTIONS.START: {
      return {
        ...state,
        status: RequestStatus.LOADING,
      }
    }
    case FETCH_ACTIONS.UPDATE: {
      return {
        ...state,
        ...action.payload
      }
    }
    case FETCH_ACTIONS.FINISH_SUCCESS: {
      return {
        ...state,
        status: RequestStatus.SUCCESS,
        data: action.payload.data,
        headers: action.payload.headers,
        fail: false,
      }
    }
    case FETCH_ACTIONS.FINISH_ERROR: {
      return {
        ...state,
        status: RequestStatus.ERROR,
        error: action.payload,
        fail: true,
      }
    }
    case FETCH_ACTIONS.RESET: {
      return initialState;
    }
  }
};


export function useFetch<T = TODO_ANY>(): [data: IFetchState<T>, query: typeQueryFunction, update: typeUpdateFunction<T> ] {
  const [state, dispatch] = useReducer(reducerFn, initialState);

  const updateState = useCallback((payload: Partial<IFetchState<T>>) => {
    dispatch({
      type: FETCH_ACTIONS.UPDATE,
      payload
    });
  }, []);

  const query: typeQueryFunction = useCallback(async function getData(queryFn, ...arg) {
    dispatch({type: FETCH_ACTIONS.START});

    try {
      const data = await queryFn(...arg);

      if(data.status >= 200 && data.status < 300) {
        dispatch({type: FETCH_ACTIONS.FINISH_SUCCESS, payload: {data: data.data, headers: data.headers}});
        return true;
      }
    } catch (err) {
      logger.log(err);
      dispatch({type: FETCH_ACTIONS.FINISH_ERROR, payload: err})
      return false;
    };
    return false;
  }, []);


  return [ state, query, updateState ];
};

