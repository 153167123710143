import { colors } from '@/styles/colors';
import { styled } from '@mui/material/styles';

const StyledDiv = styled('div')`
  position: sticky;
  z-index: 99;
  top: 0;
  left: 0;
  width: 100%;

  .top {
    position: sticky;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #e1f5fc;

    .logo {
      display: flex;
      align-items: center;

      p {
        color: #00040f;
        font-size: 14px;
      }
    }

    .btn {
      button {
        border-radius: 10px;
        background-color: #0c88c6;
        border: none;
        color: #fff;
        padding: 8px 12px;
        cursor: pointer;
      }
    }
  }

  .search-container {
    /* background-image: ${colors.primaryGradient}; */
    background-color: #fff;
    padding-top: 10px;
    padding-bottom: 16px;
    position: relative;
    z-index: 2;

    .logoText {
      font-family: Montserrat;
    }
  }

  .dropdown-title {
    font-size: 14px;
    line-height: 18px;
    font-weight: 500;
    font-family: Inter;
    color: #00040f;
  }

  .category-container {
    background-color: ${colors.snow};
    padding-top: 10px;
    padding-right: 0;
    padding-left: 0;
    padding-bottom: 10px;
    transition: transform 0.5s ease;
    position: relative;
    z-index: 1;

    &__visible {
      transform: translateY(-100%);
    }

    &__hidden {
      transform: translateY(0);
    }

    &__cat-btn {
      margin-left: 15px;
    }
    &__btn {
      span {
        font-size: ${props => props.theme.customTheme.sizes.small};
      }
      &:last-child {
        margin-right: 15px;
      }
    }
  }

  .search-input {
    border: none;
    background-color: #f2f4f9;
    box-shadow: none !important;
    color: #9da0a9;
    outline: none;
    &:focus {
      border: none;
    }
  }
`;

const StyledDrawer = styled('div')`
  display: flex;
  flex-direction: column;
  button {
    display: flex;
    align-items: center;
    text-align: start;
    gap: 1rem;
    background-color: transparent;
    border: none;
    width: 100%;
  }

  hr {
    width: 100%;
  }
`;
export { StyledDiv, StyledDrawer };
