import { IKeyValue } from "@/Types/CommonTypes";
import { getClientCoordinates } from "@/Services/Coordinates";
import { IMinCategory } from "@/Models/Category";
import { deviceType } from "@/Page/SearchPage/Helpers/getPremiumCount";
import { PRODUCT_PAGE_SIZE } from "@/Data/Constants";

export function normalizeQueryParamsV2(query: any, device: deviceType): IKeyValue {
  const params = { sort: 'newest,desc', page: 1, size: PRODUCT_PAGE_SIZE, ...getClientCoordinates(), ...query };
  return params;
}

// TODO delete qilish kerak ishlatilmasa
export function normalizeQueryParams(query: any, getDefault: boolean, device: deviceType): IKeyValue {
  let normalQuery: IKeyValue = {};
  
  if(typeof query === 'object') {
    Object.entries(query).map(([name, value]) => {
      switch(name) {
        case 'name': {
          normalQuery.name = value;
          return;
        }
        // case 'address': {
        //   normalQuery.district = value;
        //   return;
        // }
        case 'max_price':
        case 'min_price':
        case 'sort':
        case 'sort_type':
        case 'condition':
        case 'currency_type': {
          normalQuery[name] = value;
          return;
        }
        case 'page': {
          const _num: number = Number(value);
          if(isNaN(_num)) return;
          normalQuery[name] = _num;
          return;
        }
        // case 'size': {
        //   const _num: number = Number(value);
        //   if(isNaN(_num)) return;
        //   normalQuery[name] = _num;
        //   return;
        // }
        case 'category_id': {
          const [id, text] = (value as string).split(',')
          if(isNaN(Number(id))) return;
          normalQuery[name] = id;
          return;
        }
      }
    });

    const params = { sort: 'newest,desc', page: 1, size: PRODUCT_PAGE_SIZE, ...getClientCoordinates(), ...normalQuery };
    // logger.log('HOMEPAGE_FIRST_REQUEST_PARAMS: ', params);
    return params;
  } else {
    return getDefault ? { page: 1, size: PRODUCT_PAGE_SIZE, ...getClientCoordinates() } : {};
  }
};

export function normalizeQueryParamsBody(query: any, getDefault: boolean, device: deviceType) {
  const params = normalizeQueryParamsV2(query, device);
  if(Object.hasOwn(params, 'page')) {
    params.page = params.page - 1;
  }
  return params;
}

export function getNormalizePageParam(query: IKeyValue): number {
  if(!query.page) {
    return 1;
  };

  return query.page;
}

export function getNormalizeCategoryParam(query: IKeyValue): IMinCategory | null {
  if(!query.category_id) {
    return null;
  };

  const [id, ...name] = query.category_id.split(',');

  return {id, nameUz: name.join(''), nameRu: name.join(''), nameEn: name.join('')} as IMinCategory;
}