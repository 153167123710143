import React, { useCallback, useEffect, useState } from 'react';
import { StyledDiv, StyledDrawer } from './style';
import SearchInput from '@/Components/moleculas/SearchInput';
import Container from '@/Components/atoms/Container';
import { Box, IconButton, Stack, SvgIcon, Typography } from '@mui/material';
import { LuSettings2 } from 'react-icons/lu';
import { useTranslation } from 'next-i18next';
import { useFetch } from '@/Hooks/useFetch';
import CategoryApi from '@/Services/Axios/Controllers/CategoryApi';
import { IMinCategory } from '@/Models/Category';
import { useScrollDirection } from '@/Hooks/useScrollDirection';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Image from '@/Components/atoms/Image';
import { useRouter } from 'next/router';
import { isIOS, isAndroid, deviceDetect } from 'react-device-detect';
import OpenApp from '@/Components/atoms/OpenApp';
import { useIsMobile } from '@/Hooks/useIsMobile';
import dynamic from 'next/dynamic';
import { useAddressChange } from '@/Components/moleculas/AddressDropdown/useAddressChange';
import MobileDrawer from '@/Components/atoms/MobileDrawer';
import { useContextSelector } from 'use-context-selector';
import { GlobalSiteSettingsContext } from '@/Context/GlobalSiteSettings';
import { getNameKeyByLang } from '@/Utils/getNameKeyByLang';
import { useMyTranslation } from '@/Hooks/useMyTranslation';
import { addressService } from '@/Models/Region/region.service';
const AddressDropdown = dynamic(() => import('@/Components/moleculas/AddressDropdown'), {
  ssr: false,
});

type Props = {
  onSubmit: (key: string, address: number) => void;
  openCategory: () => void;
  openFilter: () => void;
  resetFilter: () => void;
  onSelectCategory: (cat: IMinCategory) => void;
  hasFilterBtn?: boolean;
};

const HomeMobileHeader = ({ onSubmit, openCategory, openFilter, onSelectCategory, resetFilter, hasFilterBtn = true }: Props) => {
  // @ts-ignore
  const { t, i18n } = useTranslation(['common']);
  const router = useRouter();
  const currentLanguage = i18n.language as 'uz' | 'ru';
  const [categoryState, fetchCategory] = useFetch<IMinCategory[]>();
  const [visibleCategory, setVisibleCategory] = useState(true);
  const isMobile = useIsMobile();
  const [visibleFilter, setVisibleFilter] = useState(false);
  const options = useContextSelector(GlobalSiteSettingsContext, v => v.state.regionOptions);
  const { language } = useMyTranslation();
  const [activeRegion, setActiveRegion] = useState(0);
  const { dropdownTitle, pickRegion, regionValue } = useAddressChange();

  const onCloseFilter = useCallback(() => {
    setVisibleFilter(false);
    setActiveRegion(0);
  }, []);

  const onClickLogo = () => {
    // resetFilter();
    router.push('/');
  };

  useEffect(() => {
    fetchCategory(CategoryApi.getAllCategories);
  }, []);

  useScrollDirection(
    () => setVisibleCategory(false),
    () => setVisibleCategory(true)
  );

  const clickOpenApp = () => {
    if (isAndroid === true) {
      router.push('https://play.google.com/store/apps/details?id=uz.joyla.store');
    }
    if (isIOS === true) {
      router.push('https://apps.apple.com/uz/app/joyla-mahalliy-elonlar/id1481609705');
    }
  };
  // @ts-ignore
  const findRegionById = (obj, regions) => {
    for (const region of regions) {
      if (obj.type === 'region') {
        if (region.id === obj.id) {
          return region;
        }
      }
      if (obj.type === 'region' && obj.id === 15) {
        return null;
      }
      if (obj.type === 'district') {
        if (region.id === obj.id) {
          return region;
        }
      }
      if (obj.type === 'district') {
        if (region?.districts?.length > 0) {
          // @ts-ignore
          const foundRegion = findRegionById(obj, region.districts);
          if (foundRegion) {
            return foundRegion;
          }
        }
      }
    }
    return null;
  };

  const addressSlug = router.query.regionSlug as string;
  const addresId = addressService.getAddressValue(addressSlug);
  const region = findRegionById(addresId, options);

  return (
    <StyledDiv>
      {router.pathname === '/' ? (
        <div className='top'>
          <Container>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <div className='logo'>
                <Image priority src='/static/icons/logo.svg' width={32} height={32} alt='Joyla' className='logo-img' />
                <p> {t('open-app')}</p>
              </div>
              <div className='btn'>
                <button onClick={clickOpenApp}>{t('open')}</button>
              </div>
            </div>
          </Container>
        </div>
      ) : (
        ''
      )}
      <Container className='search-container'>
        <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} sx={{ columnGap: '7px' }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <img src='/static/icons/logo.svg' width={'28px'} height={'28px'} alt='' />
            <p onClick={onClickLogo} className='logoText'>
              JOYLA
            </p>
          </Box>
          <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} gap={'8px'}>
            <svg width='18' height='22' viewBox='0 0 18 22' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <path
                fill-rule='evenodd'
                clip-rule='evenodd'
                d='M9 0C4.02944 0 0 4.02944 0 9C0 12.3542 1.93326 15.582 3.85678 17.8902C4.83357 19.0623 5.84656 20.0441 6.69242 20.7407C7.11397 21.0878 7.50818 21.3759 7.84699 21.5833C8.01547 21.6865 8.1867 21.7799 8.35221 21.8506C8.49244 21.9106 8.73024 22 9 22C9.26976 22 9.50756 21.9106 9.64779 21.8506C9.8133 21.7799 9.98453 21.6865 10.153 21.5833C10.4918 21.3759 10.886 21.0878 11.3076 20.7407C12.1534 20.0441 13.1664 19.0623 14.1432 17.8902C16.0667 15.582 18 12.3542 18 9C18 4.02944 13.9706 0 9 0ZM9 12.5C10.933 12.5 12.5 10.933 12.5 9C12.5 7.067 10.933 5.5 9 5.5C7.067 5.5 5.5 7.067 5.5 9C5.5 10.933 7.067 12.5 9 12.5Z'
                fill='#9DA0A9'
              />
            </svg>
            <span className='dropdown-title' onClick={() => setVisibleFilter(true)}>
              {region ? (currentLanguage === 'uz' ? region?.nameUz : region?.nameRu) : language === 'uz'
      ? "O'zbekiston bo'ylab"
      : 'По всему Узбекистану'}
            </span>
          </Stack>
        </Stack>
        <Stack direction={'row'} spacing={1}>
          <SearchInput onSubmit={onSubmit} />
        </Stack>
      </Container>

      <MobileDrawer keepMounted bordered open={visibleFilter} onClose={onCloseFilter} title={t('choose-regions')!}>
        <StyledDrawer className='wrapper'>
          {activeRegion === 0 ? (
            options.map(region => (
              <>
                <button key={region.id} onClick={() => setActiveRegion(region.id)}>
                  <svg width='18' height='22' viewBox='0 0 18 22' fill='none' xmlns='http://www.w3.org/2000/svg'>
                    <path
                      fill-rule='evenodd'
                      clip-rule='evenodd'
                      d='M9 0C4.02944 0 0 4.02944 0 9C0 12.3542 1.93326 15.582 3.85678 17.8902C4.83357 19.0623 5.84656 20.0441 6.69242 20.7407C7.11397 21.0878 7.50818 21.3759 7.84699 21.5833C8.01547 21.6865 8.1867 21.7799 8.35221 21.8506C8.49244 21.9106 8.73024 22 9 22C9.26976 22 9.50756 21.9106 9.64779 21.8506C9.8133 21.7799 9.98453 21.6865 10.153 21.5833C10.4918 21.3759 10.886 21.0878 11.3076 20.7407C12.1534 20.0441 13.1664 19.0623 14.1432 17.8902C16.0667 15.582 18 12.3542 18 9C18 4.02944 13.9706 0 9 0ZM9 12.5C10.933 12.5 12.5 10.933 12.5 9C12.5 7.067 10.933 5.5 9 5.5C7.067 5.5 5.5 7.067 5.5 9C5.5 10.933 7.067 12.5 9 12.5Z'
                      fill='#9DA0A9'
                    />
                  </svg>
                  {region[getNameKeyByLang(language)]}{' '}
                  <SvgIcon sx={{ marginLeft: 'auto' }}>
                    <ChevronRightIcon />
                  </SvgIcon>
                </button>
                <hr />
              </>
            ))
          ) : (
            <>
              <button onClick={() => pickRegion({ id: options.filter(item => item.id === activeRegion)[0].id, type: 'region' })}>
                <svg width='18' height='22' viewBox='0 0 18 22' fill='none' xmlns='http://www.w3.org/2000/svg'>
                  <path
                    fill-rule='evenodd'
                    clip-rule='evenodd'
                    d='M9 0C4.02944 0 0 4.02944 0 9C0 12.3542 1.93326 15.582 3.85678 17.8902C4.83357 19.0623 5.84656 20.0441 6.69242 20.7407C7.11397 21.0878 7.50818 21.3759 7.84699 21.5833C8.01547 21.6865 8.1867 21.7799 8.35221 21.8506C8.49244 21.9106 8.73024 22 9 22C9.26976 22 9.50756 21.9106 9.64779 21.8506C9.8133 21.7799 9.98453 21.6865 10.153 21.5833C10.4918 21.3759 10.886 21.0878 11.3076 20.7407C12.1534 20.0441 13.1664 19.0623 14.1432 17.8902C16.0667 15.582 18 12.3542 18 9C18 4.02944 13.9706 0 9 0ZM9 12.5C10.933 12.5 12.5 10.933 12.5 9C12.5 7.067 10.933 5.5 9 5.5C7.067 5.5 5.5 7.067 5.5 9C5.5 10.933 7.067 12.5 9 12.5Z'
                    fill='#9DA0A9'
                  />
                </svg>
                {t('whole-region', { key: options.filter(item => item.id === activeRegion)[0][getNameKeyByLang(language)] })}
              </button>
              <hr />
              {options
                .filter(item => item.id === activeRegion)[0]
                .districts.map(district => {
                  return (
                    <>
                      <button key={district.id} onClick={() => pickRegion({ id: district.id, type: 'district' })}>
                        <svg width='18' height='22' viewBox='0 0 18 22' fill='none' xmlns='http://www.w3.org/2000/svg'>
                          <path
                            fill-rule='evenodd'
                            clip-rule='evenodd'
                            d='M9 0C4.02944 0 0 4.02944 0 9C0 12.3542 1.93326 15.582 3.85678 17.8902C4.83357 19.0623 5.84656 20.0441 6.69242 20.7407C7.11397 21.0878 7.50818 21.3759 7.84699 21.5833C8.01547 21.6865 8.1867 21.7799 8.35221 21.8506C8.49244 21.9106 8.73024 22 9 22C9.26976 22 9.50756 21.9106 9.64779 21.8506C9.8133 21.7799 9.98453 21.6865 10.153 21.5833C10.4918 21.3759 10.886 21.0878 11.3076 20.7407C12.1534 20.0441 13.1664 19.0623 14.1432 17.8902C16.0667 15.582 18 12.3542 18 9C18 4.02944 13.9706 0 9 0ZM9 12.5C10.933 12.5 12.5 10.933 12.5 9C12.5 7.067 10.933 5.5 9 5.5C7.067 5.5 5.5 7.067 5.5 9C5.5 10.933 7.067 12.5 9 12.5Z'
                            fill='#9DA0A9'
                          />
                        </svg>
                        {district[getNameKeyByLang(language)]}{' '}
                      </button>
                      <hr />
                    </>
                  );
                })}
            </>
          )}
        </StyledDrawer>
      </MobileDrawer>
    </StyledDiv>
  );
};

export default HomeMobileHeader;

// pickRegion({ id: region.id, type: 'region' })
