import { IMinCategory } from "@/Models/Category";

export const findCategoryById = (id: number, list: IMinCategory[]): (IMinCategory | void) => {
  if(!list) {
    return;
  }

  for(let index = 0; index < list.length; index++) {
    const category = list[index];


    if(category.id == id) {
      return category;
    }


    if(category.childs.length) {
      const result = findCategoryById(id, category.childs);
      if(result) {
        return result;
      }
    } else {
      if(category.id == id) {
        return category;
      }
    }
  }
}
