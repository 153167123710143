import React, { FC } from 'react';
import { Box, Drawer, DrawerProps, IconButton, Stack, Typography } from '@mui/material';
import CommonSvg, { svgNames } from '../CommonSvg';

interface IMobileDrawerProps extends Omit<DrawerProps, 'onClose'> {
  height?: string;
  bordered?: boolean;
  header?: React.ReactNode;
  onClose: (reason: 'backdropClick' | 'escapeKeyDown' | 'closeIcon') => void;
  title?: string;
  extraAction?: React.ReactNode;
}

const MobileDrawer: FC<IMobileDrawerProps> = ({
  title,
  header,
  open,
  onClose,
  extraAction,
  children,
  height = '90%',
  bordered = false,
  sx = {},
  ...rest
}) => {
  return (
    <Drawer
      open={open}
      onClose={onClose}
      anchor='bottom'
      sx={{
        '.MuiPaper-root': {
          borderRadius: bordered ? '34px 34px 0px 0px' : '',
          padding: '0.5rem 1rem 3rem',
          height: height,
          flexGrow: 1,
        },
        ...sx,
      }}
      {...rest}
    >
      <Box
        sx={{
          overflow: 'auto',
          height: '100%',
        }}
      >
        {header ? (
          header
        ) : (
          <Stack
            direction={'row'}
            justifyContent={'space-between'}
            alignItems={'center'}
            style={{ margin: 0, marginBottom: '15px', top: 0, backgroundColor: '#fff', zIndex: 2 }}
            position={'sticky'}
          >
            <div style={{ minWidth: '40px' }}>{extraAction}</div>
            <Typography variant='h5' textAlign={'center'}>
              {title}
            </Typography>
            <IconButton onClick={() => onClose('closeIcon')} disableRipple>
              <CommonSvg name={svgNames.Close} />
            </IconButton>
          </Stack>
        )}
        {children}
      </Box>
    </Drawer>
  );
};

export default MobileDrawer;
