import { useEffect, useRef } from "react";


export function useScrollDirection(onDown: () => void, onUp: () => void) {
  const ref = useRef({
    onDown,
    onUp
  });

  ref.current.onDown = onDown;
  ref.current.onUp = onUp;

  useEffect(() => {
    let lastScrollTop = 0;
    const handler = () => {
      var st = window.scrollY || document.documentElement.scrollTop;
      if (st > lastScrollTop) {
        ref.current.onUp();
      } else if (st < lastScrollTop) {
        ref.current.onDown();
      }
      lastScrollTop = st <= 0 ? 0 : st;
    };

    window.addEventListener('scroll', handler);

    return () => window.removeEventListener('scroll', handler);
  }, []);
}
